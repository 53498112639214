import { useRouter } from "next/router";
import * as storeApi from "../../../../api/store/storeQueryApiV2"

import { useEffect, useState } from "react";
import { useClient } from "../../../../state/hooks/useClientState";
import { useRootApp } from "../../../../state/hooks/useRootAppState/useRootAppState";
import { ServiceType } from "../../../../state/hooks/useActiveStore/useActiveStore";
import { Location, StoreInfo } from "../../../../api/store/storeQueryTypes";
import { sleep } from "../../../menu/SuperRenderer/sub/utils";


export interface Options {
    channelType: "WEBSITE" | "MOBILE"
    initialData?: storeApi.QueryResult2
    location?: Location
    disableAutoLoad?: boolean

}


interface State {
    mode: "initial-loading" | "loaded" | "query-loading"
    data?: storeApi.QueryResult2
    options?: storeApi.QueryOptions
}

const cleanString = (str: string) => {
    return str.replace(/['".,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
        .toLowerCase()
        .trim();
}


const useStoreLocator = (opts: Options) => {
    const [state, setState] = useState<State>({ 
        mode: !!opts.initialData ? "loaded" : "initial-loading", 
        data: opts.initialData 
    })
    const router = useRouter()

    const rootApp = useRootApp()


    const loadDefault = async () => {
        console.log("@loadDefault")
        return query({})
    }

   

    const query = async (qopts: Partial<storeApi.QueryOptions>) => {

        const loadedOptions = state.options || {
            channelType: opts.channelType,
            tagGroupItemIds: [],     
        }

        const finalOptions: storeApi.QueryOptions = {
            ...loadedOptions,
            ...qopts     
        }

        setState({ mode: "query-loading", options: finalOptions })

        const resp = await storeApi.queryStoresByTags(finalOptions)
        if (resp?.status !== 200) {
            return
        }

        const data = resp.data


        if (!data || !data.stores) {
            setState((prev) => ({ ...prev, mode: "loaded" }))
            return
        }

        if (finalOptions?.location) {
            data.stores = data.stores.sort((x, y) => (x.distance || 10000) - (y.distance || 10000))
        } else {
            data.stores = data.stores.sort((x, y) => {

                const xname = cleanString(x.name)
                const yname = cleanString(y.name)

                return xname.localeCompare(yname, 'en', { sensitivity: 'base' })
            });

        }


        setState({ mode: "loaded", data: data, options: finalOptions })


    }


    const loadWithFilters = async (tagGroupItemIds: number[]) => {
        console.log("@loadWithFilters")
        await query({ tagGroupItemIds })   
    }

    const loadWithServiceType = async (serviceType?: string) => {
        console.log("@loadWithServiceType")
        await query({ serviceType })   
    }

    const loadWithLocation = async (location: Location) => {
        console.log("@loadWithLocation", location)
        await query({ location })
    }

    const selectStore = async (store: StoreInfo, serviceType: ServiceType ) => {
        console.log("@useStoreLocator", {store, serviceType})
        rootApp.selectStore(store, serviceType)
    }

    const load = async () => {
        
        if (opts.location && opts.location.lat && opts.location.lng) {
            await loadWithLocation(opts.location)
        } else {
            await loadDefault()
        }
    }




    useEffect(() => {
        if (opts.disableAutoLoad) {
            console.log("@useStoreLocator/disableAutoLoad")
            return
        }

        load()
             
        window.addEventListener('popstate', load);
        
        return () => {
          window.removeEventListener('popstate', load);
        };
      }, []);


    return { state, loadWithFilters, load, loadWithServiceType, loadWithLocation, selectStore }
}


export type Handle = ReturnType<typeof useStoreLocator>


export default useStoreLocator;