import Link from "next/link";
import Button from "../button";
import { openStatus, StoreInfo } from "../../../../api/store/storeQueryTypes";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
export interface PropsType {
  onServiceTypeSelect?: (serviceType: "DELIVERY" | "TAKE_AWAY") => void;
  store: StoreInfo;
  details?: boolean;
  altPickupText?: string
  altDeliveryText?: string
  disablePickup?: boolean
  disableDelivery?: boolean
  isClosest?: boolean
}

const AddressBlob = (props: PropsType) => {
  const router = useRouter();
  const store = props.store;

  return (
    <div className={`flex gap-[16px] flex-col items-start self-stretch p-[16px] w-full rounded-[8px] sup-card md:gap-[12px] md:h-[17.25rem] sup-store-card sup-animate`}>
      <div className="flex flex-col gap-[8px]">
        <h3 className="text-[16px] font-font-family-body font-semibold">
          {props.store.name}
        </h3>



        {!!store.distance && (

          <>


            <h3 className="text-[12px] font-font-family-body font-normal italic">

              {props.isClosest ? (<>
                Closest store

              </>) : (<>
                {store.distance.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                km away
              </>)}
            </h3>


          </>
        )}

      </div>

      {/* TAGS */}

      {store.status === "offline" && (
        <>
          <Tag text={"Temporary Closed"} acentColor={"bg-red-500"} tagSlug={store.status} />
        </>
      )}

      {store.status === "closed" && (
        <>
          <Tag text={"Closed"} acentColor={"bg-red-500"} tagSlug={store.status} />{" "}
        </>
      )}

      {store.status === "closing-soon" && (
        <>
          <Tag
            text={`Closing soon ${store.openTime?.split("-")[1] || ""}`}
            acentColor={"bg-yellow-500"}
            tagSlug={store.status}
          />
        </>
      )}

      {store.status === "open-24h" && (
        <>
          <Tag
            text={"Open 24hrs"}
            acentColor={"bg-accents-primary-accent"}
            tagSlug={store.status}

          />
        </>
      )}

      {store.status === "open" && (
        <>
          <Tag
            text={`accepting online orders`}
            acentColor={"bg-accents-primary-accent"}
            tagSlug={store.status}
          />
        </>
      )}

      {store.status === "closed-opens-today" && (
        <>
          <Tag
            text={`Opens at ${store.openTime?.split("-")[0] || ""}`}
            acentColor={"bg-yellow-500"}
            tagSlug={store.status}
          />
        </>
      )}

      {store.status === "opening-soon" && (
        <>
          <Tag
            text={`Opening soon ${store.openTime}`}
            acentColor={"bg-yellow-500"}
            tagSlug={store.status}
          />
        </>
      )}





      <div className="flex flex-col gap-[20px] py-[16px] w-full">
        <p className="text-[14px] font-font-family-body font-normal">
          Choose an option to order
        </p>
        <div className="flex gap-[12px] items-center self-stretch text-white">

          {(!props.disableDelivery && props.store.uiDeliveryEnabled ) && (<>
            <Button
              text={props.altDeliveryText || "Delivery"}
              bgColor={"sup-service-btn sup-service-btn-delivery"}
              onClick={(ev) => {
                ev.stopPropagation();
                props.onServiceTypeSelect && props.onServiceTypeSelect("DELIVERY");
              }}
            />
          </>)}

          {(!props.disablePickup && props.store.uiTakeAwayEnabled) && (<>
            <Button
              text={props.altPickupText || "Pick up"}
              onClick={(ev) => {
                ev.stopPropagation();
                props.onServiceTypeSelect && props.onServiceTypeSelect("TAKE_AWAY");

              }}
              bgColor={"sup-service-btn sup-service-btn-pickup"}
            />
          </>)}


        </div>
      </div>
      {!props.details ? (
        <></>
      ) : (
        <div className="flex gap-[10px] justify-between w-full pb-2">
          <Link
            href={router.pathname.startsWith("/[host]/mobile") ? `/mobile/store-locator/${store.uuid}-${store.id}` : `/store-locator/${store.uuid}-${store.id}`}
            className="text-[14px] font-font-family-body font-normal sup-link"
          >
            Store details
          </Link>


          {openStatus.includes(store.status) ? (<>
            <div className="px-[10px] py-[2px] rounded-[9999px] text-xs bg-white text-gray-800 sup-store-tag sup-store-tag-open-alt">
              <span>open now</span>
            </div>

          </>) : (<>
            <div className="px-[10px] py-[2px] rounded-[9999px] text-xs text-white bg-red-500 sup-store-tag sup-store-tag-open-alt">
              <span>closed</span>
            </div>

          </>)}




        </div>
      )}
    </div>
  );
};

interface TagProps {
  text: string;
  tagSlug: string;
  acentColor:
  | "bg-accents-secondary-accent"
  | "bg-accents-primary-accent"
  | string;
}

const Tag = (props: TagProps) => {
  return (
    <div className={`px-[10px] py-[8px] rounded-[9999px] text-white ${props.acentColor} sup-store-tag sup-store-tag-${props.tagSlug}`}>
      <p className="text-[12px] font-font-family-body font-normal leading-[10px]">
        {props.text}
      </p>
    </div>
  );
};

export default AddressBlob;
