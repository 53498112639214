import LoadingSkeleton from "./sub/LoadingSkeleton";
import useStoreLocator, { Handle, Options } from "./sub/useStoreLocator";
import AddressBlob from "../../../components/templates/ui-update/address";
import { useEffect, useState } from "react";
import { Location } from "../../../api/store/storeQueryTypes";
import { useClient } from "../../../state/hooks/useClientState";

interface PropsType {
  location?: Location;
}

const HomeStoreListings = (props: PropsType) => {
  const handle = useStoreLocator({
    channelType: "WEBSITE",
    location: props.location,
    disableAutoLoad: true
  });

  const cdata = useClient()
  const attrs = cdata.siteJson.siteAttributes || {}
  const pickupText = attrs.pickupText;
  const deliveryText = attrs.deliveryText;

  const disableDelivery = attrs.disableDelivery;
  const disablePickup = attrs.disablePickup;


  useEffect(() => {
    if (!props.location || !props.location.lat || !props.location.lng) {
      console.log("No location provided");
      return;
    }

    console.log("Loading location:", props.location);

    handle.loadWithLocation(props.location);
  }, [props.location]);

  return (
    <>
      {handle.state.mode !== "loaded" && <LoadingSkeleton />}

      {handle.state.mode === "loaded" && (
        <>
          <div className="flex flex-col gap-[12px] items-start self-stretch md:flex-row md:flex-wrap sup-animate-parent">
            {handle.state.data?.stores.map((store, index) => (
              <>
                <div className={`w-full md:w-[358px] sup-store-card sup-animate`}>
                  <AddressBlob
                    disableDelivery={disableDelivery}
                    disablePickup={disablePickup}
                    altDeliveryText={deliveryText}
                    altPickupText={pickupText}
                    key={store.id}
                    store={store}
                    details={true}                    
                    onServiceTypeSelect={(serviceType) => {
                      handle.selectStore(store, serviceType);
                    }}
                  />
                </div>
              </>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default HomeStoreListings;
