
// syncme => storeGroups/filter/queryTypes.ts


export interface QueryOptions {
    serviceType?: string
    location?: Location
    queryfilters: any[]
    channelType: "WEBSITE" | "MOBILE"
}

export interface Location {
    lat: number
    lng: number
}


export interface Result {
    stores: StoreInfo[]
    options: QueryOptions
    filters: any[]
    isFullLoad: boolean
}


export interface StoreInfo {
    id: number
    name: string
    uuid: string
    distance?: number
    deliveryFee?: number
    status: StoreStatusType
    phoneNumbers: string[]
    openTime?: string
    tradingInfo?: any
    addressInfo?: any
    location?: Location
    groups: string[]
    uiDeliveryEnabled?: boolean
    uiTakeAwayEnabled?: boolean
}

// if you modify donot forgot to update cms editor thing in client-platform

export type StoreStatusType = "open" | "open-24h" | "closed-opens-today" | "opening-soon" | "closed" | "closing-soon" | "offline" 


export const openStatus = [
    "open",
    "open-24h",
    "closing-soon"
] 