import { useRouter } from "next/router";
import { useClient } from "../../../../state/hooks/useClientState";
import BannerImage from "./sub/Banner/BannerImage";
import AddressInput from "../../../../components/templates/ui-update/AddressInput";
import Button from "../../../../components/templates/ui-update/button";
import { useRootApp } from "../../../../state/hooks/useRootAppState/useRootAppState";
import { useState } from "react";
import HomeStoreListings from "../../../store/listings/HomeStoreListings";
import HeadingTitle from "./sub/HeadingTitle";

const Homepage = (props: any) => {
  const router = useRouter();
  const cdata = useClient();
  const rootApp = useRootApp();
  const [location, setLocation] = useState<any>(null);

  const activeStore = rootApp.activeStore.state;
  const authState = rootApp.auth.state;



  const bannerData = (props["data"] || []).sort(
    (x: any, y: any) => x["order"] - y["order"]
  );

  // const secondaryBanner = bannerData.find(
  //   (item: any) => item["imageType"] === "home_secondary"
  // );

  const primaryBanners = bannerData.filter(
    (item: any) => item["imageType"] === "home_primary"
  );

  return (
    <>
      <div className="flex flex-col gap-[20px] md:max-w-[1100px] md:w-full md:mx-auto md:pt-[20px]">
        <div className="flex flex-col gap-[20px] items-center self-stretch p-[20px] leading-[22px] md:pb-0 md:pt-[24px] "> 
          <HeadingTitle />
          
          <div className="w-full flex flex-col gap-[20px] items-center md:flex-row md:items-stretch">
            <div className="w-full md:w-[calc(69.811%-20px)]">
              <AddressInput
                disableAutoLoad={true}
                onLocationChange={(ldata) => {
                  setLocation(ldata.location);
                }}
              />
            </div>
            <div className="w-full md:w-[calc(32.076%-20px)] flex self-start">
              <Button
                className="h-[48px]"
                onClick={() => {
                  router.push("/all-stores");
                }}
              />
            </div>
          </div>
        </div>

        {!location && (
          <>
            <div className="flex flex-col gap-[20px] px-[20px]">
              <h3 className="text-[20px] font-font-family-brand-font font-bold">
                Specials
              </h3>
              <div className="flex flex-col gap-[20px] padding-bot pb-[20px]">
                {primaryBanners.map((banner: any) => {
                  return (
                    <BannerImage key={banner.id} {...banner} clientUuid={cdata.clientUuid} />
                  );
                })}
              </div>
            </div>
          </>
        )}

        {location && (
          <>
            <HomeStoreListings location={location} />
          </>
        )}
      </div>
    </>
  );
};

export default Homepage;
