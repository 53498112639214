

const LoadingSkeleton = () => {
  return (
    <div className="space-y-5 rounded-2xl  p-4 animate-pulse flex-grow flex flex-col">

      <div className=" mx-auto w-full p-1">
        <div className="flex flex-col gap-2">
          <div className="h-6 w-3/5 bg-gray-600/20"></div>
          <div className="h-8 w-4/5 bg-gray-600/10 rounded"></div>



        </div>
      </div>


      <LoadingSkeletonStorsOnly showFilter={true} />
    </div>
  );
};


export const LoadingSkeletonStorsOnly = (props: {showFilter: boolean}) => {

  return (<>

    <div className="flex flex-col gap-4">
      <div className="bg-gray-600/10 rounded flex justify-between py-2">
        <div className="flex flex-col gap-4 p-2">
          <div className="h-4 w-10 bg-gray-600/20"></div>
          <div className="h-4 w-10 bg-gray-600/10"></div>
        </div>
        <div className="flex flex-col gap-4 p-2">
          <div className="h-4 w-4 bg-gray-600/20"></div>
          <div className="h-4 w-4 bg-gray-600/10"></div>
        </div>
      </div>

      <div className="flex flex-wrap gap-2 space-y-2 flex-grow mx-auto w-full p-1">
        {Array.from(Array(10)).map((v, idx) => (<>
          <div className="w-full sm:w-80 md:w-[358px]">
            <div className="flex flex-col gap-4 justify-between rounded-lg bg-gray-600/10 p-4">
              <div className="flex flex-col gap-4">
                <div className="h-5 w-4/5 bg-gray-600/10"></div>
                <div className="h-2 w-2/5 bg-gray-600/10"></div>
                <div className="h-6 w-12 rounded-lg bg-gray-600/10"></div>
                <div className="h-2 w-3/5 bg-gray-600/10"></div>
              </div>

              <div className="flex gap-4">
                <div className="h-8 w-20 bg-gray-600/10 rounded-md"></div>
                <div className="h-8 w-20 bg-gray-600/10 rounded-md"></div>
              </div>


              <div className="flex justify-between">
                <div className="h-2 w-10 bg-gray-600/10 p-2"></div>
                <div className="h-3 w-10 bg-gray-600/10 p-2"></div>
              </div>
            </div>
          </div>
        </>))}
      </div>
    </div>





  </>)

}

export default LoadingSkeleton;