import Image from "next/image";
import cloudflareCustomImageLoader from "../../../../../../scripts/cloudflareCustomImageLoader";
import { bucketURLBuilder } from "../../../../../../utils/bucket";
import { bannerSizes } from "../../../../../../utils/imgUtils";
import { useEffect, useState } from "react";
interface PropTypes {
  desktopFilePath: string;
  altText: string;
  link?: string;
  clientUuid: string;

}


const BannerImage = (props: PropTypes) => {


  return (
    <div className={`w-full self-stretch rounded-[8px] min-h-48 md:min-h-[500px] h-auto relative sup-animate`}>
      <Image
        className="w-full h-auto object-cover"      
        loader={cloudflareCustomImageLoader}
        src={bucketURLBuilder(props.clientUuid, props.desktopFilePath)}
        layout="fill"
        objectFit="contain"        
        alt={props.altText || ""}
        sizes={bannerSizes()}
        priority={true}
        onClick={(ev) => {
          if (props.link) {
            window.open(props.link)
          }
        }}
      />


    </div>
  );
};

export default BannerImage;
